import React, { useState } from "react"
import { connect } from "react-redux"
import Modal from "react-modal"

import { modalActions } from "../../_actions"

import { AssignApplication } from "../../../workflow/modals/AssignModal"
import { ApproveApplication } from "../../../workflow/modals/ApproveModal"
import DisburseRefuseModal from "../../../workflow/modals/DisburseRefuseModal"
import { ActivateUser } from "../../../userManager/modals/ActivateUser"
import { DeactivateUser } from "../../../userManager/modals/DeactivateUser"
import { AddFormula } from "../../../scoreLogic/modals/AddFormula"
import { EditFormula } from "../../../scoreLogic/modals/EditFormula"
import { DeleteFormula } from "../../../scoreLogic/modals/DeleteFormula"
import { AddCashflowVariable } from "../../../scoreLogic/modals/AddCashflowVariable"
import { DeleteCashflowVariable } from "../../../scoreLogic/modals/DeleteCashflowVariable"
import { AddScoringVariablesCondition } from "../../../scoreLogic/modals/AddScoringVariablesCondition"
import { EditScoringVariablesCondition } from "../../../scoreLogic/modals/EditScoringVariablesCondition"
import { DeleteScoringVariablesCondition } from "../../../scoreLogic/modals/DeleteScoringVariablesCondition"
import { AddScoringVariablesVariable } from "../../../scoreLogic/modals/AddScoringVariablesVariable"
import { AddScoringVariablesEntry } from "../../../scoreLogic/modals/AddScoringVariablesEntry"
import { AddScoreCardCondition } from "../../../scoreLogic/modals/AddScoreCardCondition"
import { DeleteScoreCardCondition } from "../../../scoreLogic/modals/DeleteScoreCardCondition"
import { AddAssumption } from "../../../scoreLogic/modals/AddAssumption"
import { EditAssumption } from "../../../scoreLogic/modals/EditAssumption"
import { DeleteAssumption } from "../../../scoreLogic/modals/DeleteAssumption"
import { DeleteScoringVariablesEntry } from "../../../scoreLogic/modals/DeleteScoringVariablesEntry"
import { RecalculateScore } from "../../../applicationManager/modals/RecalculateScore"
import { RecalculateScores } from "../../../applicationManager/modals/RecalculateScores"
import { ManageMFISubRoles } from "../../../mfiManager/modals/ManageMFISubRoles"
import { DeleteMFISubrole } from "../../../mfiManager/modals/DeleteMFISubrole"
import { AddWorkflowGroup } from "../../../mfiManager/modals/AddWorkflowGroup"
import { DeleteApplicationChecklist } from "../../../documentChecklist/modals/DeleteApplicationChecklist"
import { UploadApplicationChecklist } from "../../../documentChecklist/modals/UploadApplicationChecklist"
import { PreviewApplicationChecklist } from "../../../documentChecklist/modals/PreviewApplicationChecklist"
import { DeleteDocumentChecklist } from "../../../scoreLogic/modals/DeleteDocumentChecklist"
import { ExportApplications } from "../../../applicationManager/modals/ExportApplications"
import { ImportPayments } from "../../../applicationManager/modals/ImportPayments"
import { DeleteApplicationColumn } from "../../../config/modals/DeleteApplicationColumn"
import { DateColumnFilter } from "../../../applicationManager/modals/DateColumnFilter"
import { DeleteCreditScoreReportSetup } from "../../../config/modals/DeleteCreditScoreReportSetup"
import { UserFilter } from "../../../applicationManager/modals/UserFilter"
import { EditHierarchy } from "../../../mfiManager/modals/EditHierarchy"
import { AddHierarchy } from "../../../mfiManager/modals/AddHierarchy"
import { DateFilter } from "../../../reports/modals/DateFilter"
import { OfficeFilter } from "../../../reports/modals/OfficeFilter"
import { AddQuestionnaire } from "../../../mfiManager/modals/AddQuestionnaire"
import { EditQuestionnaire } from "../../../mfiManager/modals/EditQuestionnaire"
import { DeleteQuestionnaire } from "../../../mfiManager/modals/DeleteQuestionnaire"
import { DeactivateQuestionnaire } from "../../../mfiManager/modals/DeactivateQuestionnaire"
import { ActivateQuestionnaire } from "../../../mfiManager/modals/ActivateQuestionnaire"
import { ResetQuestionnaire } from "../../../mfiManager/modals/ResetQuestionnaire"
import { ManageCustomization } from "../../../mfiManager/modals/ManageCustomization"
import { AutoLogoutNotification } from "../../../authentication/modals/AutoLogoutNotification"
import PaymentDecline from "../../../applicationManager/modals/PayDecline"
import PaymentMethod from "../../../applicationManager/modals/PaymentMethod"
import ConfirmMobileUpdate from "../../../applicationManager/modals/ConfirmMobileUpdate"
import AddBeneficiary from "../../../applicationManager/modals/AddBeneficiary"
import AddVillageBank from "../../../applicationManager/modals/AddVillageBank"
import ApprovePayCollection from "../../../applicationManager/modals/ApprovePayCollection"
import "./ModalRoot.scss"
import { ListClientDetails } from "../../../client/components/modals/getClientProfile"
import { AssignToModal } from "../../../client/components/modals/AssignToModal"
import { AssignBackModal } from "../../../client/components/modals/AssignBackModal"
import { AssignToOnClientProfileModal } from "../../../client/components/modals/AssignToOnClientProfileModal"

import { BMSendReminder } from "../../../client/components/modals/sendReminder"
import { RepaymentsDateFilter } from "../../../reports/modals/repaymentsDateFilter"
import { ApplicationOfficeFilter } from "../../../applicationManager/modals/ApplicationOfficeFilter"
import { PurchaseFilter } from "../../../applicationManager/modals/PurchasFilter"
import { PaymentFilter } from "../../../reports/modals/PaymentFilter"

const MODAL_COMPONENTS = {
  ASSIGN: AssignApplication,
  APPROVE: ApproveApplication,
  DISBURSE: DisburseRefuseModal,
  REFUSE: DisburseRefuseModal,
  PAY_DECLINE: PaymentDecline,
  COLLECTION: ApprovePayCollection,
  ADD_VILLAGE_BANK: AddVillageBank,
  PAYMENT_METHOD: PaymentMethod,
  MOBILE_UPDATE: ConfirmMobileUpdate,
  ADD_BENEFICIARY: AddBeneficiary,
  ACTIVATE_USER: ActivateUser,
  DEACTIVATE_USER: DeactivateUser,
  ADD_FORMULA: AddFormula,
  EDIT_FORMULA: EditFormula,
  DELETE_FORMULA: DeleteFormula,
  ADD_CASHFLOW_VARIABLE: AddCashflowVariable,
  DELETE_CASHFLOW_VARIABLE: DeleteCashflowVariable,
  ADD_SCORING_VARIABLES_CONDITION: AddScoringVariablesCondition,
  EDIT_SCORING_VARIABLES_CONDITION: EditScoringVariablesCondition,
  DELETE_SCORING_VARIABLES_CONDITION: DeleteScoringVariablesCondition,
  ADD_SCORING_VARIABLES_VARIABLE: AddScoringVariablesVariable,
  ADD_SCORING_VARIABLES_ENTRY: AddScoringVariablesEntry,
  DELETE_SCORING_VARIABLES_ENTRY: DeleteScoringVariablesEntry,
  ADD_SCORE_CARD_CONDITION: AddScoreCardCondition,
  DELETE_SCORE_CARD_CONDITION: DeleteScoreCardCondition,
  ADD_ASSUMPTION: AddAssumption,
  EDIT_ASSUMPTION: EditAssumption,
  DELETE_ASSUMPTION: DeleteAssumption,
  RECALCULATE_SCORE: RecalculateScore,
  RECALCULATE_SCORES: RecalculateScores,
  MANAGE_MFI_SUBROLES: ManageMFISubRoles,
  DELETE_MFI_SUBROLES: DeleteMFISubrole,
  MANAGE_WORKFLOW_GROUPS: AddWorkflowGroup,
  MANAGE_CUSTOMIZATION: ManageCustomization,
  AUTO_LOGOUT: AutoLogoutNotification,
  DELETE_APPLICATION_CHECKLIST: DeleteApplicationChecklist,
  UPLOAD_APPLICATION_CHECKLIST: UploadApplicationChecklist,
  PREVIEW_APPLICATION_CHECKLIST: PreviewApplicationChecklist,
  DELETE_DOCUMENT_CHECKLIST: DeleteDocumentChecklist,
  EXPORT_APPLICATIONS: ExportApplications,
  IMPORT_PAYMENTS: ImportPayments,
  DELETE_APPLICATION_COLUMN: DeleteApplicationColumn,
  DATE_COLUMN_FILTER_MODAL: DateColumnFilter,
  DELETE_CREDIT_SCORE_REPORT_MODAL: DeleteCreditScoreReportSetup,
  USER_FILTER_MODAL: UserFilter,
  OFFICE_FILTER_MODAL: ApplicationOfficeFilter,
  PURCHASE_FILTER_MODAL: PurchaseFilter,
  EDIT_HIERARCHY_MODAL: EditHierarchy,
  ADD_HIERARCHY_MODAL: AddHierarchy,
  REPORTS_DATE_FILTER_MODAL: DateFilter,
  REPAYMMENT_REPORTS_DATE_FILTER_MODAL: RepaymentsDateFilter,
  PAYMENT_REPORT_FILTER: PaymentFilter,
  REPORTS_OFFICE_FILTER_MODAL: OfficeFilter,
  ADD_QUESTIONNAIRE_MODAL: AddQuestionnaire,
  EDIT_QUESTIONNAIRE_MODAL: EditQuestionnaire,
  DELETE_QUESTIONNAIRE_MODAL: DeleteQuestionnaire,
  DEACTIVATE_QUESTIONNAIRE_MODAL: DeactivateQuestionnaire,
  ACTIVATE_QUESTIONNAIRE_MODAL: ActivateQuestionnaire,
  RESET_QUESTIONNAIRE_MODAL: ResetQuestionnaire,
  GET_CLIENT_DETAILS: ListClientDetails,
  CLIENT_ASSIGN_TO: AssignToModal,
  CLIENT_ASSIGN_BACK: AssignBackModal,
  CLIENT_ASSIGN_TO_ON_PROFILE: AssignToOnClientProfileModal,
  SEND_REMINDER: BMSendReminder,
}

Modal.setAppElement(document.getElementById("root"))

const ModalRoot = ({ type, content, dispatch }) => {
  const [modalIsOpen, setModalIsOpen] = useState(true)

  if (!type) {
    return null
  }

  const closeModal = () => {
    setModalIsOpen(false)
    dispatch(modalActions.clear())
  }

  if (type === "HIDE_MODAL") {
    closeModal()
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[type]

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 0,
          zIndex: 9999,
          backgroundImage:
            "radial-gradient( circle at 50% 0px, rgba(241, 248, 255, 0.9), rgba(238, 244, 249, 0.9) )",
        },
        content: {
          position: "relative",
          top: "auto",
          left: "auto",
          right: "auto",
          bottom: "auto",
          border: "none",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          background: "transparent",
        },
      }}
    >
      {SpecificModal && (
        <SpecificModal modalID={1} type={type} data={content} />
      )}
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { modals } = state
  return modals
}

const connectedModalRoot = connect(mapStateToProps)(ModalRoot)
export { connectedModalRoot as ModalRoot }
