/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useRef, useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { configActions } from "../../"
import {
  FormWithConstraints,
  Input,
} from "react-form-with-constraints-bootstrap4"
import { FormSelect, FormGroup } from "shards-react"
import { isEmpty, filter, first } from "lodash"

import "./ReportDetails.scss"
import { useTranslation } from "react-i18next"

export function ReportDetails() {
  const scoreLogic = useSelector((store) => store.scoreLogic)
  const { t } = useTranslation()
  const { uid, computationCodes, scoreCardProfile } = scoreLogic
  const config = useSelector((store) => store.config)
  const {
    applicantGenderGlobalState,
    applicantAgeGlobalState,
    applicantMaritalStatusGlobalState,
    applicantLoanGlobalState,
    newApplicantGlobalState,
    landSizeGlobalState,
  } = config

  const [surveyComputationCodes, setSurveyComputationCodes] = useState([])
  const [submitAge, setSubmitAge] = useState(false)
  const [submitMaritalStatus, setSubmitMaritalStatus] = useState(false)
  const [submitLoan, setSubmitLoan] = useState(false)
  const [submitGender, setSubmitGender] = useState(false)
  const [submitNew, setSubmitNew] = useState(false)
  const [submitNewLand, setSubmitNewLand] = useState(false)
  const dispatch = useDispatch()
  const form = useRef(null)

  const defaultApplicantGenderState = {
    computationCode: "",
    questionKey: "",
    title: t("module.applications.reportDetails.applicantGender"),
    type: "APPLICANT_GENDER",
  }
  const [applicantGenderState, setApplicantGenderState] = useState(
    defaultApplicantGenderState
  )
  const [genderAnswerState, setGenderAnswerState] = useState("")

  const defaultApplicantAgeState = {
    computationCode: "",
    questionKey: "",
    title: t("module.applications.reportDetails.applicantAge"),
    type: "APPLICANT_AGE",
  }
  const [applicantAgeState, setApplicantAgeState] = useState(
    defaultApplicantAgeState
  )
  const [ageAnswerState, setAgeAnswerState] = useState("")

  const defaultApplicantMaritalStatusState = {
    computationCode: "",
    questionKey: "",
    title: t("module.applications.reportDetails.applicantMaritalStatus"),
    type: "APPLICANT_MARITAL_STATUS",
  }
  const [applicantMaritalStatusState, setApplicantMaritalStatusState] =
    useState(defaultApplicantMaritalStatusState)
  const [maritalstatusAnswerState, setMaritalStatusAnswerState] = useState("")

  const defaultApplicantLoanState = {
    computationCode: "",
    questionKey: "",
    title: t("module.applications.reportDetails.applicantLoan"),
    type: "APPLICANT_LOAN",
  }
  const [applicantLoanState, setApplicantLoanState] = useState(
    defaultApplicantLoanState
  )
  const [loanAnswerState, setLoanAnswerState] = useState("")

  const defaultNewApplicantState = {
    computationCode: "",
    questionKey: "",
    title: t("module.applications.reportDetails.newApplicant"),
    type: "NEW_APPLICANT",
  }
  const [newApplicantState, setNewApplicantState] = useState(
    defaultNewApplicantState
  )
  const [newAnswerState, setNewAnswerState] = useState("")

  const defaultNewLandState = {
    surveyUuid: "",
    computationCode: "",
    questionKey: "",
    type: "LAND",
  }
  const [newLandState, setNewLandState] = useState(defaultNewLandState)

  // update state data
  useEffect(() => {
    if (!submitGender) {
      const applicantGender = first(
        scoreCardProfile.filter((item) => {
          const { type, surveyUuid } = item
          return type === "APPLICANT_GENDER" && surveyUuid === uid
        })
      )
      setApplicantGenderState({
        ...defaultApplicantGenderState,
        ...applicantGender,
      })
    }
    if (!submitAge) {
      const applicantAge = first(
        scoreCardProfile.filter((item) => {
          const { type, surveyUuid } = item
          return type === "APPLICANT_AGE" && surveyUuid === uid
        })
      )
      setApplicantAgeState({
        ...defaultApplicantAgeState,
        ...applicantAge,
      })
    }

    if (!submitMaritalStatus) {
      const applicantMaritalStatus = first(
        scoreCardProfile.filter((item) => {
          const { type, surveyUuid } = item
          return type === "APPLICANT_MARITAL_STATUS" && surveyUuid === uid
        })
      )
      setApplicantMaritalStatusState({
        ...defaultApplicantMaritalStatusState,
        ...applicantMaritalStatus,
      })
    }

    if (!submitLoan) {
      const applicantLoan = first(
        scoreCardProfile.filter((item) => {
          const { type, surveyUuid } = item
          return type === "APPLICANT_LOAN" && surveyUuid === uid
        })
      )
      setApplicantLoanState({
        ...defaultApplicantLoanState,
        ...applicantLoan,
      })
    }

    if (!submitNew) {
      const newApplicant = first(
        scoreCardProfile.filter((item) => {
          const { type, surveyUuid } = item
          return type === "NEW_APPLICANT" && surveyUuid === uid
        })
      )
      setNewApplicantState({ ...defaultNewApplicantState, ...newApplicant })
    }

    if (!submitNewLand) {
      const NewLand = first(
        scoreCardProfile.filter((item) => {
          const { type, surveyUuid } = item
          return type === "LAND" && surveyUuid === uid
        })
      )
      setNewLandState({ ...defaultNewLandState, ...NewLand })
    }
  }, [])

  useEffect(() => {
    if (
      applicantGenderGlobalState ||
      applicantAgeGlobalState ||
      applicantMaritalStatusGlobalState ||
      applicantLoanGlobalState ||
      newApplicantGlobalState ||
      landSizeGlobalState
    ) {
      setApplicantGenderState(applicantGenderGlobalState)
      setApplicantAgeState(applicantAgeGlobalState)
      setAgeAnswerState(applicantAgeGlobalState)
      setApplicantMaritalStatusState(applicantMaritalStatusGlobalState)
      setMaritalStatusAnswerState(applicantMaritalStatusGlobalState)
      setApplicantLoanState(applicantLoanGlobalState)
      setLoanAnswerState(applicantLoanGlobalState)
      setGenderAnswerState(applicantGenderGlobalState)
      setNewApplicantState(newApplicantGlobalState)
      setNewAnswerState(newApplicantGlobalState)
      setNewLandState(landSizeGlobalState)
    }
  }, [
    applicantGenderGlobalState,
    applicantAgeGlobalState,
    applicantMaritalStatusGlobalState,
    applicantLoanGlobalState,
    newApplicantGlobalState,
    landSizeGlobalState,
  ])

  useEffect(() => {
    if (submitNewLand) {
      newLandState.computationCode &&
        dispatch(configActions.updateLandSize(newLandState))
    }
  }, [submitNewLand])

  useEffect(() => {
    if (submitNew) {
      newApplicantState.computationCode &&
        dispatch(configActions.updateNewApplicant(newApplicantState))
    }
  }, [submitNew])

  useEffect(() => {
    if (submitGender) {
      applicantGenderState.computationCode &&
        dispatch(configActions.updateApplicantGender(applicantGenderState))
    }
  }, [submitGender])

  useEffect(() => {
    if (submitAge) {
      applicantAgeState.computationCode &&
        dispatch(configActions.updateApplicantAge(applicantAgeState))
    }
  }, [submitAge])

  useEffect(() => {
    if (submitMaritalStatus) {
      applicantMaritalStatusState.computationCode &&
        dispatch(
          configActions.updateApplicantMaritalStatus(
            applicantMaritalStatusState
          )
        )
    }
  }, [submitMaritalStatus])

  useEffect(() => {
    if (submitLoan) {
      applicantLoanState.computationCode &&
        dispatch(configActions.updateApplicantLoan(applicantLoanState))
    }
  }, [submitLoan])

  const handleApplicantGenderChange = (e) => {
    const { value } = e.target
    const ComputationCodes = first(
      surveyComputationCodes.filter(
        ({ questionCode }) => questionCode === value
      )
    )
    setApplicantGenderState({
      ...applicantGenderState,
      computationCode: value,
      questionKey: ComputationCodes.questionKey,
      surveyUuid: uid,
      type: "APPLICANT_GENDER",
    })
    setSubmitGender(true)
  }

  const handleApplicantAgeChange = (e) => {
    const { value } = e.target
    const ComputationCodes = first(
      surveyComputationCodes.filter(
        ({ questionCode }) => questionCode === value
      )
    )
    setApplicantAgeState({
      ...applicantAgeState,
      computationCode: value,
      questionKey: ComputationCodes.questionKey,
      surveyUuid: uid,
      type: "APPLICANT_AGE",
    })
    setSubmitAge(true)
  }
  const handleApplicantMaritalStatusChange = (e) => {
    const { value } = e.target
    const ComputationCodes = first(
      surveyComputationCodes.filter(
        ({ questionCode }) => questionCode === value
      )
    )
    setApplicantMaritalStatusState({
      ...applicantMaritalStatusState,
      computationCode: value,
      questionKey: ComputationCodes.questionKey,
      surveyUuid: uid,
      type: "APPLICANT_MARITAL_STATUS",
    })
    setSubmitMaritalStatus(true)
  }

  const handleApplicantLoanChange = (e) => {
    const { value } = e.target
    const ComputationCodes = first(
      surveyComputationCodes.filter(
        ({ questionCode }) => questionCode === value
      )
    )
    setApplicantLoanState({
      ...applicantLoanState,
      computationCode: value,
      questionKey: ComputationCodes.questionKey,
      surveyUuid: uid,
      type: "APPLICANT_LOAN",
    })
    setSubmitLoan(true)
  }

  const handleNewApplicantChange = (e) => {
    const { value } = e.target
    const ComputationCodes = first(
      surveyComputationCodes.filter(
        ({ questionCode }) => questionCode === value
      )
    )
    setNewApplicantState({
      ...newApplicantState,
      computationCode: value,
      questionKey: ComputationCodes.questionKey,
      surveyUuid: uid,
      type: "NEW_APPLICANT",
    })
    setSubmitNew(true)
  }

  const handleNewLandChange = (e) => {
    const { value } = e.target
    const ComputationCodes = first(
      surveyComputationCodes.filter(
        ({ questionCode }) => questionCode === value
      )
    )
    setNewLandState({
      ...newLandState,
      computationCode: value,
      questionKey: ComputationCodes.questionKey,
      surveyUuid: uid,
      type: "LAND",
    })
    setSubmitNewLand(true)
  }

  useEffect(() => {
    const codes =
      computationCodes &&
      computationCodes.filter(({ surveyUuid }) => surveyUuid === uid)
    if (!isEmpty(codes)) {
      setSurveyComputationCodes([...codes])
    } else {
      setSurveyComputationCodes([])
    }
  }, [computationCodes, uid])

  const getCodeLabel = (xCode) => {
    const [filtered] = filter(surveyComputationCodes, { questionCode: xCode })
    const questionCode = Object(filtered)
    return `${questionCode.questionLabel} (${xCode})`
  }

  return (
    <div>
      <div className="group p-4 w-1/2">
        <h4 className="mini-heading-top">
          {t("module.applications.reportDetails.applicantGender")}
        </h4>
        <FormGroup className="group flex">
          <label className="pr-4">
            {t("module.applications.applicationProfile.question")}
          </label>
          <FormSelect
            name="expression1"
            value={applicantGenderState && applicantGenderState.computationCode}
            size="sm"
            className="mr-auto max-w-md"
            onChange={(e) => handleApplicantGenderChange(e)}
          >
            <option key={-1} value="">
              {t("module.applications.applicationProfile.selectQuestion")}
            </option>
            {surveyComputationCodes &&
              surveyComputationCodes.map((questionnaire, idx) => {
                return (
                  <option key={idx} value={questionnaire.questionCode}>
                    {getCodeLabel(questionnaire.questionCode)}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
        <FormGroup className="flex">
          <label style={{ width: "138px" }}>
            {t("module.applications.applicationProfile.computationCode")}
          </label>
          <div className="projectionState-expression">
            {applicantGenderState && applicantGenderState.computationCode}
          </div>
        </FormGroup>
      </div>
      <div className="group p-4 w-1/2">
        <h4 className="mini-heading-top">
          {t("module.applications.reportDetails.applicantAge")}
        </h4>
        <FormGroup className="group flex">
          <label className="pr-4">
            {t("module.applications.applicationProfile.question")}
          </label>
          <FormSelect
            name="expression1"
            value={applicantAgeState && applicantAgeState.computationCode}
            size="sm"
            className="mr-auto max-w-md"
            onChange={(e) => handleApplicantAgeChange(e)}
          >
            <option key={-1} value="">
              {t("module.applications.applicationProfile.selectQuestion")}
            </option>
            {surveyComputationCodes &&
              surveyComputationCodes.map((questionnaire, idx) => {
                return (
                  <option key={idx} value={questionnaire.questionCode}>
                    {getCodeLabel(questionnaire.questionCode)}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
        <FormGroup className="flex">
          <label style={{ width: "138px" }}>
            {t("module.applications.applicationProfile.computationCode")}
          </label>
          <div className="projectionState-expression">
            {applicantAgeState && applicantAgeState.computationCode}
          </div>
        </FormGroup>
      </div>
      <div className="group p-4 w-1/2">
        <h4 className="mini-heading-top">
          {t("module.applications.reportDetails.applicantMaritalStatus")}
        </h4>
        <FormGroup className="group flex">
          <label className="pr-4">
            {t("module.applications.applicationProfile.question")}
          </label>
          <FormSelect
            name="expression1"
            value={
              applicantMaritalStatusState &&
              applicantMaritalStatusState.computationCode
            }
            size="sm"
            className="mr-auto max-w-md"
            onChange={(e) => handleApplicantMaritalStatusChange(e)}
          >
            <option key={-1} value="">
              {t("module.applications.applicationProfile.selectQuestion")}
            </option>
            {surveyComputationCodes &&
              surveyComputationCodes.map((questionnaire, idx) => {
                return (
                  <option key={idx} value={questionnaire.questionCode}>
                    {getCodeLabel(questionnaire.questionCode)}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
        <FormGroup className="flex">
          <label style={{ width: "138px" }}>
            {t("module.applications.applicationProfile.computationCode")}
          </label>
          <div className="projectionState-expression">
            {applicantMaritalStatusState &&
              applicantMaritalStatusState.computationCode}
          </div>
        </FormGroup>
      </div>
      <div className="group p-4 w-1/2">
        <h4 className="mini-heading-top">
          {t("module.applications.reportDetails.applicantLoan")}
        </h4>
        <FormGroup className="group flex">
          <label className="pr-4">
            {t("module.applications.applicationProfile.question")}
          </label>
          <FormSelect
            name="expression1"
            value={applicantLoanState && applicantLoanState.computationCode}
            size="sm"
            className="mr-auto max-w-md"
            onChange={(e) => handleApplicantLoanChange(e)}
          >
            <option key={-1} value="">
              {t("module.applications.applicationProfile.selectQuestion")}
            </option>
            {surveyComputationCodes &&
              surveyComputationCodes.map((questionnaire, idx) => {
                return (
                  <option key={idx} value={questionnaire.questionCode}>
                    {getCodeLabel(questionnaire.questionCode)}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
        <FormGroup className="flex">
          <label style={{ width: "138px" }}>
            {t("module.applications.applicationProfile.computationCode")}
          </label>
          <div className="projectionState-expression">
            {applicantLoanState && applicantLoanState.computationCode}
          </div>
        </FormGroup>
      </div>
      <div className="group p-4 w-1/2">
        <h4 className="mini-heading-top">
          {t("module.applications.reportDetails.newApplicant")}
        </h4>
        <FormGroup className="group flex">
          <label className="pr-4">
            {t("module.applications.applicationProfile.question")}
          </label>
          <FormSelect
            name="expression1"
            value={newApplicantState && newApplicantState.computationCode}
            size="sm"
            className="mr-auto max-w-md"
            onChange={(e) => handleNewApplicantChange(e)}
          >
            <option key={-1} value="">
              {t("module.applications.applicationProfile.selectQuestion")}
            </option>
            {surveyComputationCodes &&
              surveyComputationCodes.map((questionnaire, idx) => {
                return (
                  <option key={idx} value={questionnaire.questionCode}>
                    {getCodeLabel(questionnaire.questionCode)}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
        <FormGroup className="flex">
          <label style={{ width: "138px" }}>
            {t("module.applications.applicationProfile.computationCode")}
          </label>
          <div className="projectionState-expression">
            {newApplicantState && newApplicantState.computationCode}
          </div>
        </FormGroup>
      </div>

      {/* land calculations */}
      <div className="group p-4 w-1/2">
        <h4 className="mini-heading-top">
          {t("module.applications.reportDetails.landTitle")}
        </h4>
        <FormGroup className="group flex">
          <label className="pr-4">
            {t("module.applications.applicationProfile.question")}
          </label>
          <FormSelect
            name="expression1"
            value={newLandState && newLandState.computationCode}
            size="sm"
            className="mr-auto max-w-md"
            onChange={(e) => handleNewLandChange(e)}
          >
            <option key={-1} value="">
              {t("module.applications.applicationProfile.selectQuestion")}
            </option>
            {surveyComputationCodes &&
              surveyComputationCodes.map((questionnaire, idx) => {
                return (
                  <option key={idx} value={questionnaire.questionCode}>
                    {getCodeLabel(questionnaire.questionCode)}
                  </option>
                )
              })}
          </FormSelect>
        </FormGroup>
        <FormGroup className="flex">
          <label style={{ width: "138px" }}>
            {t("module.applications.applicationProfile.computationCode")}
          </label>
          <div className="projectionState-expression">
            {newLandState && newLandState.computationCode}
          </div>
        </FormGroup>
      </div>
    </div>
  )
}
