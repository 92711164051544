import React from "react"
import { WithWizard } from "react-albus"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Button } from "shards-react"
import { configActions } from ".."

function WizardActions({ scoreLogic, dispatch, setPoint }) {
  const { uid } = scoreLogic
  let disable = !uid ? true : false
  const { t } = useTranslation()

  const handleSaveClose = () => {
    dispatch(configActions.addReportQuestion())
    setTimeout(function () {
      window.location.href = "/config"
    }, 1800)
  }

  return (
    <WithWizard
      render={({ next, previous, step }) => {
        const { id } = step
        return (
          <div key={id} className="wizard-actions">
            {id === "setQuestionaire" && (
              <Button
                className="next"
                onClick={() => {
                  setPoint(2)
                  next()
                }}
                disabled={disable}
              >
                {t("module.scoreLogic.component.wizardActions.continue")}
              </Button>
            )}
            {id !== "customerProfile" && id !== "setQuestionaire" && (
              <Button className="next" onClick={() => next()}>
                {t("module.scoreLogic.component.wizardActions.continue")}
              </Button>
            )}
            {id === "customerProfile" && (
              <Button className="next" onClick={() => handleSaveClose()}>
                {t("module.scoreLogic.component.wizardActions.saveClose")}
              </Button>
            )}
          </div>
        )
      }}
    />
  )
}

function mapStateToProps(state) {
  const { scoreLogic } = state
  return { scoreLogic }
}

const connectedWizardActions = connect(mapStateToProps)(WizardActions)
export { connectedWizardActions as WizardActions }
