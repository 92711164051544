import React from "react"
import ReactApexChart from "react-apexcharts"
import { withStyles } from "@material-ui/core/styles"
import styles from "./Chart.styles"
import { CircularProgress, Typography } from "@material-ui/core"
import { useSelector } from "react-redux"

export default withStyles(styles)(function ApexChart({
  cashFlow,
  classes,
  loading,
  type,
  report,
}) {
  const applications = useSelector((state) => state.applications)

  const {
    profile: {
      mfi: { mfiName: mfiName, currency: settingsCurrency },
    },
  } = useSelector((store) => store.authentication)

  const loantenor = applications.items[0].approvalLoanTenor
  const LoanAmount = report?.find(
    (cf) =>
      cf.label.toLowerCase().includes("loan") ||
      cf.label.toLowerCase().includes("loan_amount")
  )?.value

  const MonthlyHouseholdIncome = cashFlow?.find(
    (cf) =>
      cf.label.toLowerCase().includes("household income") ||
      cf.label.toLowerCase().includes("monthly_household_income") ||
      cf.label.toLowerCase().includes("hh_inc_monthly")
  )?.value

  let MonthlyHouseholdIncomevalue = 0
  if (MonthlyHouseholdIncome && MonthlyHouseholdIncome.length > 0) {
    MonthlyHouseholdIncomevalue = MonthlyHouseholdIncome[0]
  }
  const MonthlyHouseholdExpense = cashFlow?.find(
    (cf) =>
      cf.label.toLowerCase().includes("household expen") ||
      cf.label.toLowerCase().includes("monthly_household_expen") ||
      cf.label.toLowerCase().includes("hh_exp_monthly")
  )?.value
  let MonthlyHouseholdExpensevalue = 0
  if (MonthlyHouseholdExpense && MonthlyHouseholdExpense.length > 0) {
    MonthlyHouseholdExpensevalue = MonthlyHouseholdExpense[0]
  }

  const MonthlyFarmExpense = cashFlow?.find(
    (cf) =>
      cf.label.toLowerCase().includes("farm expen") ||
      cf.label.toLowerCase().includes("monthly_farm_income") ||
      cf.label.toLowerCase().includes("farm_exp_monthly")
  )?.value

  const MonthlyBusinessExpense = cashFlow?.find(
    (cf) =>
      cf.label.toLowerCase().includes("business expen") ||
      cf.label.toLowerCase().includes("monthly_business_income") ||
      cf.label.toLowerCase().includes("business_inc_monthly")
  )?.value

  let MonthlyActivityExpensevalue = 0
  if (MonthlyFarmExpense && MonthlyFarmExpense.length > 0) {
    MonthlyActivityExpensevalue = MonthlyFarmExpense[0]
  } else if (MonthlyBusinessExpense && MonthlyBusinessExpense.length > 0) {
    MonthlyActivityExpensevalue = MonthlyBusinessExpense[0]
  }

  const MonthlyFarmIncome = cashFlow?.find(
    (cf) =>
      cf.label.toLowerCase().includes("farm income") ||
      cf.label.toLowerCase().includes("monthly_farm_income") ||
      cf.label.toLowerCase().includes("farm_inc_monthly")
  )?.value

  const MonthlyBusinessIncome = cashFlow?.find(
    (cf) =>
      cf.label.toLowerCase().includes("business income") ||
      cf.label.toLowerCase().includes("monthly_business_income") ||
      cf.label.toLowerCase().includes("business_inc_monthly")
  )?.value

  let MonthlyActivityIncomevalue = 0
  if (MonthlyFarmIncome && MonthlyFarmIncome.length > 0) {
    MonthlyActivityIncomevalue =
      Math.round(parseFloat(MonthlyFarmIncome[0]) * 100) / 100
  } else if (MonthlyBusinessIncome && MonthlyBusinessIncome.length > 0) {
    MonthlyActivityIncomevalue =
      Math.round(parseFloat(MonthlyBusinessIncome[0]) * 100) / 100
  }
  const monthlyIncome =
    parseFloat(MonthlyActivityIncomevalue) +
    parseFloat(MonthlyHouseholdIncomevalue)

  const monthlyExpense =
    parseFloat(MonthlyActivityExpensevalue) +
    parseFloat(MonthlyHouseholdExpensevalue)

  const loan = cashFlow?.filter(
    (cf) =>
      cf.label.toLowerCase().includes("outstanding") ||
      cf.label.toLowerCase().includes("outstanding_loan")
  )
  const monthlydebt = LoanAmount / loantenor

  const netIncome = monthlyIncome

  const netExpense = monthlyExpense

  let outstandingLoan = loan?.reduce((acc, cf) => acc + Number(cf?.value[0]), 0)
  outstandingLoan = outstandingLoan < 10 ? 0 : outstandingLoan

  const netCashFlow = netIncome - netExpense

  let MaxDebtCapacity
  if (mfiName.toLowerCase() === "centenary") {
    MaxDebtCapacity = netCashFlow < 0 ? 0 : netCashFlow / 2
  } else {
    MaxDebtCapacity = netCashFlow < 0 ? 0 : netCashFlow / 3
  }

  const series = [
    {
      name: `Cash Flow in ${settingsCurrency}`,
      data: [
        Math.floor(netIncome),
        -Math.floor(netExpense),
        Math.floor(netCashFlow),
        Math.floor(MaxDebtCapacity),
      ],
    },
  ]

  const nCfColor = netCashFlow < 0 ? "#FF0000" : "#008d6a"
  const diColor = MaxDebtCapacity < 0 ? "#FF0000" : "#008d6a"
  const colors = ["#008d6a", "#FF0000", nCfColor, diColor]

  const annotationText =
    netCashFlow > 0 ? "+ve Monthly net cash flow" : "-ve Monthly net cash flow"

  const options = {
    annotations: {
      points: [
        {
          x: "Net Montly Cash Flow",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#60c1f7",
            },
            text: annotationText,
          },
        },
      ],
    },
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors,
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "50%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 2,
    },

    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: [
        "Monthly Net Income",
        "Monthly Net Expenses",
        "Net Montly Cash Flow",
        "Max Debt Capacity",
      ],
      tickPlacement: "on",
    },
    tooltip: {
      y: {
        formatter: (value) => value.toLocaleString(),
      },
    },
    yaxis: {
      title: {
        text: `Cash Flow in ${settingsCurrency}`,
      },
    },
  }

  if (loading)
    return (
      <div className={classes.noChartLoaded}>
        <CircularProgress />
      </div>
    )

  if (!cashFlow || cashFlow?.length < 1)
    return (
      <div className={classes.noChartLoaded}>
        <Typography className={classes.notAvailableText} variant="h5">
          {`${type} cash flow analysis not available`}
        </Typography>
      </div>
    )

  return (
    <div id="chart" className={classes.chartContainerNet}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="100%"
      />
    </div>
  )
})
