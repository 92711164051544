import { modalConstants } from "./_constants"

const initialState = {
  type: null,
  content: {},
}

export const modals = (state = initialState, action) => {
  switch (action.type) {
    case modalConstants.DELETE:
      return {
        content: action.content,
        type: modalConstants.DELETE,
      }

    case modalConstants.NOTE:
      return {
        content: action.content,
        type: modalConstants.NOTE,
      }

    case modalConstants.ASSIGN:
      return {
        content: action.content,
        type: modalConstants.ASSIGN,
      }
    case modalConstants.APPROVE:
      return {
        content: action.content,
        type: modalConstants.APPROVE,
      }
    case modalConstants.DISBURSE:
      return {
        content: action.content,
        type: modalConstants.DISBURSE,
      }
    case modalConstants.REFUSE:
      return {
        content: action.content,
        type: modalConstants.REFUSE,
      }

    case modalConstants.ACTIVATE_USER:
      return {
        content: action.content,
        type: modalConstants.ACTIVATE_USER,
      }

    case modalConstants.DEACTIVATE_USER:
      return {
        content: action.content,
        type: modalConstants.DEACTIVATE_USER,
      }

    case modalConstants.ADD_FORMULA:
      return {
        content: action.content,
        type: modalConstants.ADD_FORMULA,
      }

    case modalConstants.EDIT_FORMULA:
      return {
        content: action.content,
        type: modalConstants.EDIT_FORMULA,
      }

    case modalConstants.DELETE_FORMULA:
      return {
        content: action.content,
        type: modalConstants.DELETE_FORMULA,
      }
    case modalConstants.ADD_CASHFLOW_VARIABLE:
      return {
        content: action.content,
        type: modalConstants.ADD_CASHFLOW_VARIABLE,
      }
    case modalConstants.DELETE_CASHFLOW_VARIABLE:
      return {
        content: action.content,
        type: modalConstants.DELETE_CASHFLOW_VARIABLE,
      }
    case modalConstants.ADD_SCORING_VARIABLES_CONDITION:
      return {
        content: action.content,
        type: modalConstants.ADD_SCORING_VARIABLES_CONDITION,
      }

    case modalConstants.EDIT_SCORING_VARIABLES_CONDITION:
      return {
        content: action.content,
        type: modalConstants.EDIT_SCORING_VARIABLES_CONDITION,
      }

    case modalConstants.DELETE_SCORING_VARIABLES_CONDITION:
      return {
        content: action.content,
        type: modalConstants.DELETE_SCORING_VARIABLES_CONDITION,
      }

    case modalConstants.ADD_SCORING_VARIABLES_VARIABLE:
      return {
        content: action.content,
        type: modalConstants.ADD_SCORING_VARIABLES_VARIABLE,
      }

    case modalConstants.DELETE_SCORING_VARIABLES_ENTRY:
      return {
        content: action.content,
        type: modalConstants.DELETE_SCORING_VARIABLES_ENTRY,
      }

    case modalConstants.ADD_SCORING_VARIABLES_ENTRY:
      return {
        content: action.content,
        type: modalConstants.ADD_SCORING_VARIABLES_ENTRY,
      }
    case modalConstants.ADD_SCORE_CARD_CONDITION:
      return {
        content: action.content,
        type: modalConstants.ADD_SCORE_CARD_CONDITION,
      }

    case modalConstants.DELETE_SCORE_CARD_CONDITION:
      return {
        content: action.content,
        type: modalConstants.DELETE_SCORE_CARD_CONDITION,
      }

    case modalConstants.ADD_ASSUMPTION:
      return {
        content: action.content,
        type: modalConstants.ADD_ASSUMPTION,
      }

    case modalConstants.EDIT_ASSUMPTION:
      return {
        content: action.content,
        type: modalConstants.EDIT_ASSUMPTION,
      }

    case modalConstants.DELETE_ASSUMPTION:
      return {
        content: action.content,
        type: modalConstants.DELETE_ASSUMPTION,
      }
    case modalConstants.RECALCULATE_SCORE:
      return {
        content: action.content,
        type: modalConstants.RECALCULATE_SCORE,
      }
    case modalConstants.RECALCULATE_SCORES:
      return {
        content: action.content,
        type: modalConstants.RECALCULATE_SCORES,
      }
    case modalConstants.MANAGE_MFI_SUBROLES:
      return {
        content: action.content,
        type: modalConstants.MANAGE_MFI_SUBROLES,
      }
    case modalConstants.MANAGE_WORKFLOW_GROUPS:
      return {
        content: action.content,
        type: modalConstants.MANAGE_WORKFLOW_GROUPS,
      }
    case modalConstants.MANAGE_CUSTOMIZATION:
      return {
        content: action.content,
        type: modalConstants.MANAGE_CUSTOMIZATION,
      }
    case modalConstants.AUTO_LOGOUT:
      return {
        content: action.content,
        type: modalConstants.AUTO_LOGOUT,
      }
    case modalConstants.DELETE_MFI_SUBROLES:
      return {
        content: action.content,
        type: modalConstants.DELETE_MFI_SUBROLES,
      }
    case modalConstants.DELETE_APPLICATION_CHECKLIST:
      return {
        content: action.content,
        type: modalConstants.DELETE_APPLICATION_CHECKLIST,
      }
    case modalConstants.UPLOAD_APPLICATION_CHECKLIST:
      return {
        content: action.content,
        type: modalConstants.UPLOAD_APPLICATION_CHECKLIST,
      }
    case modalConstants.PREVIEW_APPLICATION_CHECKLIST:
      return {
        content: action.content,
        type: modalConstants.PREVIEW_APPLICATION_CHECKLIST,
      }
    case modalConstants.DELETE_DOCUMENT_CHECKLIST:
      return {
        content: action.content,
        type: modalConstants.DELETE_DOCUMENT_CHECKLIST,
      }
    case modalConstants.EXPORT_APPLICATIONS:
      return {
        content: action.content,
        type: modalConstants.EXPORT_APPLICATIONS,
      }
    case modalConstants.IMPORT_PAYMENTS:
      return {
        content: action.content,
        type: modalConstants.IMPORT_PAYMENTS,
      }
    case modalConstants.PAY_DECLINE:
      return {
        content: action.content,
        type: modalConstants.PAY_DECLINE,
      }

    case modalConstants.COLLECTION:
      return {
        content: action.content,
        type: modalConstants.COLLECTION,
      }

    case modalConstants.ADD_VILLAGE_BANK:
      return {
        content: action.content,
        type: modalConstants.ADD_VILLAGE_BANK,
      }

    case modalConstants.PAYMENT_METHOD:
      return {
        content: action.content,
        type: modalConstants.PAYMENT_METHOD,
      }
    case modalConstants.MOBILE_UPDATE:
      return {
        content: action.content,
        type: modalConstants.MOBILE_UPDATE,
      }
    case modalConstants.ADD_BENEFICIARY:
      return {
        content: action.content,
        type: modalConstants.ADD_BENEFICIARY,
      }
    case modalConstants.DELETE_APPLICATION_COLUMN:
      return {
        content: action.content,
        type: modalConstants.DELETE_APPLICATION_COLUMN,
      }
    case modalConstants.DATE_COLUMN_FILTER_MODAL:
      return {
        content: action.content,
        type: modalConstants.DATE_COLUMN_FILTER_MODAL,
      }
    case modalConstants.DELETE_CREDIT_SCORE_REPORT_MODAL:
      return {
        content: action.content,
        type: modalConstants.DELETE_CREDIT_SCORE_REPORT_MODAL,
      }
    case modalConstants.USER_FILTER_MODAL:
      return {
        type: modalConstants.USER_FILTER_MODAL,
      }
    case modalConstants.OFFICE_FILTER_MODAL:
      return {
        type: modalConstants.OFFICE_FILTER_MODAL,
        content: action.content,
      }
    case modalConstants.PURCHASE_FILTER_MODAL:
      return {
        type: modalConstants.PURCHASE_FILTER_MODAL,
        content: action.content,
      }
    case modalConstants.EDIT_HIERARCHY_MODAL:
      return {
        content: action.content,
        type: modalConstants.EDIT_HIERARCHY_MODAL,
      }
    case modalConstants.ADD_HIERARCHY_MODAL:
      return {
        content: action.content,
        type: modalConstants.ADD_HIERARCHY_MODAL,
      }

    case modalConstants.REPORTS_DATE_FILTER_MODAL:
      return {
        content: action.content,
        type: modalConstants.REPORTS_DATE_FILTER_MODAL,
      }

    case modalConstants.REPAYMMENT_REPORTS_DATE_FILTER_MODAL:
      return {
        content: action.content,
        type: modalConstants.REPAYMMENT_REPORTS_DATE_FILTER_MODAL,
      }

    case modalConstants.PAYMENT_REPORT_FILTER:
      return {
        content: action.content,
        type: modalConstants.PAYMENT_REPORT_FILTER,
      }

    case modalConstants.REPORTS_OFFICE_FILTER_MODAL:
      return {
        content: action.content,
        type: modalConstants.REPORTS_OFFICE_FILTER_MODAL,
      }

    case modalConstants.ADD_QUESTIONNAIRE_MODAL:
      return {
        content: action.content,
        type: modalConstants.ADD_QUESTIONNAIRE_MODAL,
      }

    case modalConstants.EDIT_QUESTIONNAIRE_MODAL:
      return {
        content: action.content,
        type: modalConstants.EDIT_QUESTIONNAIRE_MODAL,
      }

    case modalConstants.DELETE_QUESTIONNAIRE_MODAL:
      return {
        content: action.content,
        type: modalConstants.DELETE_QUESTIONNAIRE_MODAL,
      }

    case modalConstants.DEACTIVATE_QUESTIONNAIRE_MODAL:
      return {
        content: action.content,
        type: modalConstants.DEACTIVATE_QUESTIONNAIRE_MODAL,
      }
    case modalConstants.ACTIVATE_QUESTIONNAIRE_MODAL:
      return {
        content: action.content,
        type: modalConstants.ACTIVATE_QUESTIONNAIRE_MODAL,
      }
    case modalConstants.RESET_QUESTIONNAIRE_MODAL:
      return {
        content: action.content,
        type: modalConstants.RESET_QUESTIONNAIRE_MODAL,
      }
    case modalConstants.GET_CLIENT_DETAILS:
      return {
        content: action.content,
        type: modalConstants.GET_CLIENT_DETAILS,
      }
    case modalConstants.PREVIEW_CLIENT_UPLOAD:
      return {
        content: action.content,
        type: modalConstants.PREVIEW_CLIENT_UPLOAD,
      }
    case modalConstants.CLIENT_ASSIGN_TO:
      return {
        content: action.content,
        type: modalConstants.CLIENT_ASSIGN_TO,
      }
    case modalConstants.CLIENT_ASSIGN_TO_ON_PROFILE:
      return {
        content: action.content,
        type: modalConstants.CLIENT_ASSIGN_TO_ON_PROFILE,
      }
    case modalConstants.CLIENT_ASSIGN_BACK:
      return {
        content: action.content,
        type: modalConstants.CLIENT_ASSIGN_BACK,
      }
    case modalConstants.SEND_REMINDER:
      return {
        content: action.content,
        type: modalConstants.SEND_REMINDER,
      }
    case modalConstants.HIDE_MODAL:
    case modalConstants.CLEAR:
      return initialState
    default:
      return state
  }
}
