import { modalConstants } from "./_constants"

export const modalActions = {
  note,
  delete: _delete,
  clear,
  assign,
  approve,
  disburse,
  refuse,
  hideModal,
  activateUser,
  deactivateUser,
  addFormula,
  editFormula,
  deleteFormula,
  addCashFlowVariable,
  deleteCashFlowVariable,
  addScoringVariablesCondition,
  editScoringVariablesCondition,
  deleteScoringVariablesCondition,
  addScoringVariablesVariable,
  deleteScoringVariablesEntry,
  addScoringVariablesEntry,
  addScoreCardCondition,
  deleteScoreCardCondition,
  addAssumption,
  editAssumption,
  deleteAssumption,
  recalculateScore,
  manageMFISubRoles,
  deleteMFISubRole,
  deleteApplicationChecklist,
  uploadApplicationChecklist,
  previewApplicationChecklist,
  manageWorkflowGroups,
  deleteDocumentChecklist,
  exportApplications,
  importPayments,
  payDecline,
  approvePayCollection,
  addVillageBank,
  deleteApplicationColumn,
  dateColumnFilter,
  deleteCreditScoreReportSetup,
  userFilter,
  officeFilter,
  editHierarchy,
  addHierarchy,
  reportsDateFilter,
  reportsOfficeFilter,
  addQuestionnaire,
  editQuestionnaire,
  deleteQuestionnaire,
  deactivateQuestionnaire,
  activateQuestionnaire,
  resetQuestionnaire,
  viewClientDetails,
  sendReminder,
  clientAssignTo,
  clientAssignBack,
  clientAssignToClientProfile,
  repaymentReportsDateFilter,
  manageCustomization,
  autoLogout,
  approvePaymentAction,
  declinePaymentAction,
  purchaseFilter,
  paymentMethod,
  confirmMobileUpdate,
  addBeneficiary,
  paymentReportFilter,
  recalculateScores,
}

function note(content) {
  return { type: modalConstants.NOTE, content }
}

function assign(content) {
  return { type: modalConstants.ASSIGN, content }
}

function approve(content) {
  return { type: modalConstants.APPROVE, content }
}

function disburse(content) {
  return { type: modalConstants.DISBURSE, content }
}

function refuse(content) {
  return { type: modalConstants.REFUSE, content }
}

// eslint-disable-next-line no-underscore-dangle
function _delete(content) {
  return { type: modalConstants.DELETE, content }
}

function hideModal(content) {
  return { type: modalConstants.HIDE_MODAL, content }
}

function clear() {
  return { type: modalConstants.CLEAR }
}

function activateUser(content) {
  return { type: modalConstants.ACTIVATE_USER, content }
}

function deactivateUser(content) {
  return { type: modalConstants.DEACTIVATE_USER, content }
}

function addFormula(content) {
  return { type: modalConstants.ADD_FORMULA, content }
}

function editFormula(content) {
  return { type: modalConstants.EDIT_FORMULA, content }
}

function deleteFormula(content) {
  return { type: modalConstants.DELETE_FORMULA, content }
}

function addCashFlowVariable(content) {
  return { type: modalConstants.ADD_CASHFLOW_VARIABLE, content }
}

function deleteCashFlowVariable(content) {
  return { type: modalConstants.DELETE_CASHFLOW_VARIABLE, content }
}

function addScoringVariablesCondition(content) {
  return { type: modalConstants.ADD_SCORING_VARIABLES_CONDITION, content }
}

function editScoringVariablesCondition(content) {
  return { type: modalConstants.EDIT_SCORING_VARIABLES_CONDITION, content }
}

function deleteScoringVariablesCondition(content) {
  return { type: modalConstants.DELETE_SCORING_VARIABLES_CONDITION, content }
}

function addScoringVariablesVariable(content) {
  return { type: modalConstants.ADD_SCORING_VARIABLES_VARIABLE, content }
}

function deleteScoringVariablesEntry(content) {
  return { type: modalConstants.DELETE_SCORING_VARIABLES_ENTRY, content }
}

function addScoringVariablesEntry(content) {
  return { type: modalConstants.ADD_SCORING_VARIABLES_ENTRY, content }
}

function addScoreCardCondition(content) {
  return { type: modalConstants.ADD_SCORE_CARD_CONDITION, content }
}

function deleteScoreCardCondition(content) {
  return { type: modalConstants.DELETE_SCORE_CARD_CONDITION, content }
}

function addAssumption(content) {
  return { type: modalConstants.ADD_ASSUMPTION, content }
}

function editAssumption(content) {
  return { type: modalConstants.EDIT_ASSUMPTION, content }
}

function deleteAssumption(content) {
  return { type: modalConstants.DELETE_ASSUMPTION, content }
}

function recalculateScore(content) {
  return { type: modalConstants.RECALCULATE_SCORE, content }
}

function recalculateScores(content) {
  return { type: modalConstants.RECALCULATE_SCORES, content }
}

function manageMFISubRoles(content) {
  return { type: modalConstants.MANAGE_MFI_SUBROLES, content }
}

function deleteMFISubRole(content) {
  return { type: modalConstants.DELETE_MFI_SUBROLES, content }
}

function deleteApplicationChecklist(content) {
  return { type: modalConstants.DELETE_APPLICATION_CHECKLIST, content }
}

function uploadApplicationChecklist(id, applicationId) {
  return {
    type: modalConstants.UPLOAD_APPLICATION_CHECKLIST,
    content: { id, applicationId },
  }
}

function previewApplicationChecklist(id, modalTitle, documentUrl, application) {
  return {
    type: modalConstants.PREVIEW_APPLICATION_CHECKLIST,
    content: {
      id,
      modalTitle,
      documentUrl,
      application,
    },
  }
}

function manageWorkflowGroups(content) {
  return { type: modalConstants.MANAGE_WORKFLOW_GROUPS, content }
}

function deleteDocumentChecklist(content) {
  return { type: modalConstants.DELETE_DOCUMENT_CHECKLIST, content }
}

function exportApplications(content) {
  return { type: modalConstants.EXPORT_APPLICATIONS, content }
}

function importPayments(content) {
  return { type: modalConstants.IMPORT_PAYMENTS, content }
}

function payDecline(content) {
  return { type: modalConstants.PAY_DECLINE, content }
}

function approvePayCollection(content) {
  return { type: modalConstants.COLLECTION, content }
}

function addVillageBank(content) {
  return { type: modalConstants.ADD_VILLAGE_BANK, content }
}

function paymentMethod(content) {
  return { type: modalConstants.PAYMENT_METHOD, content }
}

function confirmMobileUpdate(content) {
  return { type: modalConstants.MOBILE_UPDATE, content }
}

function addBeneficiary(content) {
  return { type: modalConstants.ADD_BENEFICIARY, content }
}

function deleteApplicationColumn(content) {
  return { type: modalConstants.DELETE_APPLICATION_COLUMN, content }
}

function dateColumnFilter(content) {
  return { type: modalConstants.DATE_COLUMN_FILTER_MODAL, content }
}

function deleteCreditScoreReportSetup(content) {
  return { type: modalConstants.DELETE_CREDIT_SCORE_REPORT_MODAL, content }
}

function userFilter() {
  return { type: modalConstants.USER_FILTER_MODAL }
}

function officeFilter(content) {
  return { type: modalConstants.OFFICE_FILTER_MODAL, content }
}

function purchaseFilter(content) {
  return { type: modalConstants.PURCHASE_FILTER_MODAL, content }
}

function editHierarchy(content) {
  return { type: modalConstants.EDIT_HIERARCHY_MODAL, content }
}

function addHierarchy(content) {
  return { type: modalConstants.ADD_HIERARCHY_MODAL, content }
}

function reportsDateFilter(content) {
  return { type: modalConstants.REPORTS_DATE_FILTER_MODAL, content }
}

function paymentReportFilter(content) {
  return { type: modalConstants.PAYMENT_REPORT_FILTER, content }
}

function repaymentReportsDateFilter(content) {
  return { type: modalConstants.REPAYMMENT_REPORTS_DATE_FILTER_MODAL, content }
}

function approvePaymentAction(content) {
  return { type: modalConstants.REPAYMMENT_REPORTS_DATE_FILTER_MODAL, content }
}

function declinePaymentAction(content) {
  return { type: modalConstants.REPAYMMENT_REPORTS_DATE_FILTER_MODAL, content }
}

function reportsOfficeFilter(content) {
  return { type: modalConstants.REPORTS_OFFICE_FILTER_MODAL, content }
}

function addQuestionnaire(content) {
  return { type: modalConstants.ADD_QUESTIONNAIRE_MODAL, content }
}

function editQuestionnaire(content) {
  return { type: modalConstants.EDIT_QUESTIONNAIRE_MODAL, content }
}

function deleteQuestionnaire(content) {
  return { type: modalConstants.DELETE_QUESTIONNAIRE_MODAL, content }
}

function deactivateQuestionnaire(content) {
  return { type: modalConstants.DEACTIVATE_QUESTIONNAIRE_MODAL, content }
}

function activateQuestionnaire(content) {
  return { type: modalConstants.ACTIVATE_QUESTIONNAIRE_MODAL, content }
}

function resetQuestionnaire(content) {
  return { type: modalConstants.RESET_QUESTIONNAIRE_MODAL, content }
}

function viewClientDetails(id, mfiCode, refetchClients) {
  return {
    type: modalConstants.GET_CLIENT_DETAILS,
    content: { id, mfiCode, refetchClients },
  }
}

function clientAssignTo(id, mfiCode, payload, refetchClients) {
  return {
    type: modalConstants.CLIENT_ASSIGN_TO,
    content: { id, mfiCode, payload, refetchClients },
  }
}

function clientAssignToClientProfile(id, mfiCode, payload, refetchClients) {
  return {
    type: modalConstants.CLIENT_ASSIGN_TO_ON_PROFILE,
    content: { id, mfiCode, payload, refetchClients },
  }
}

function clientAssignBack(id, mfiCode, payload) {
  return {
    type: modalConstants.CLIENT_ASSIGN_BACK,
    content: { id, mfiCode, payload },
  }
}

function sendReminder(id, mfiCode) {
  return { type: modalConstants.SEND_REMINDER, content: { id, mfiCode } }
}

function manageCustomization(content) {
  return { type: modalConstants.MANAGE_CUSTOMIZATION, content }
}

function autoLogout(content) {
  return { type: modalConstants.AUTO_LOGOUT, content }
}
