import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { alertActions } from "../modules/alert";
import printJS from "print-js";

const print = (pdfFilename, elementSelector, dispatch, printFile, inner,setPrintInProgress, setDownloadInProgress) => {
  const quality = 1;

  if (!elementSelector) {
    dispatch(alertActions.error("Ensure you are on the page to be printed"));
    return;
  }

  html2canvas(elementSelector, { scale: quality }).then((canvas) => {
    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    if (printFile) {
      const printableElement = document.createElement("div");
      printableElement.id = "printable";
      printableElement.appendChild(canvas);

      if (!elementSelector.hasAttribute("id")) {
        printJS({
          printable: canvas.toDataURL(),
          type: "image",
          documentTitle: "",
        });
      } else {
        const style = `
          .fields__group {
            page-break-inside: avoid;
          }
          .form-group {
            page-break-inside: avoid;
            margin-bottom: 1rem;
          }
          .break {
            page-break-before: always;
            background-color: red;
          }
        `;
        printJS({
          printable: "printable",
          type: "html",
          targetStyles: ["*"],
          css: [
            "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
            { style },
          ],
        });
        document.getElementById("printable").innerHTML = inner;
      }
    } else {
      const pdf = new jsPDF("p", "mm", "letter");
      let position = 0;

      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        position,
        imgWidth,
        imgHeight
      );

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }

      pdf.save(pdfFilename);
      dispatch(alertActions.success("Downloading..."));
    }
  }) .catch((error) => {
    // handle error
  })
  .finally(() => {
    setPrintInProgress(false);
    setDownloadInProgress(false);
  });
};

export default print;
