/* eslint array-callback-return: 0 */
import { configConstants } from "."
import { uniqueBy } from "../../utils/uniqueBy"

/**
 *  Default State
 */
const InitialState = {
  applicationColumnSetup: [],
  applicationColumns: [],
  profileFields: [],
  cbsFields: [],
  creditScoreReportSetup: [],
  firstComments: [],
  applicantGenderGlobalState: null,
  applicantAgeGlobalState: null,
  applicantMaritalStatusGlobalState: null,
  applicantProfileGlobalState: null,
  newApplicantGlobalState: null,
  landSizeGlobalState: null,
}

/**
 * Config
 *
 * @param {*} state
 * @param {*} action
 */
export const config = (state = InitialState, action) => {
  switch (action.type) {
    case configConstants.CONFIG_APPLICATION_COLUMN_STATE_ADD_SUCCESS:
      let newApplicationColumnSetup = state.applicationColumnSetup
      const key = Object.keys(action.data)
      newApplicationColumnSetup[key] = action.data[key]
      return {
        ...state,
        applicationColumnSetup: { ...newApplicationColumnSetup },
      }
    case configConstants.CONFIG_APPLICATION_COLUMN_GET_SUCCESS:
      return {
        ...state,
        applicationColumns: action.data,
      }
    case configConstants.CONFIG_APPLICATION_COLUMN_SAVE_SUCCESS:
      return {
        ...state,
        applicationColumns: [
          ...state.applicationColumns,
          ...uniqueBy([...[action.data]], "id"),
        ],
      }
    case configConstants.CONFIG_CBS_FIELD_GET_SUCCESS:
      return {
        ...state,
        cbsFields: action.data,
      }
    case configConstants.CONFIG_CBS_FIELD_SAVE_SUCCESS:
      return {
        ...state,
        cbsFields: [...state.cbsFields, ...uniqueBy([...[action.data]], "id")],
      }
    case configConstants.CONFIG_CBS_FIELD_EDIT_SUCCESS:
      let newCbsFieldsState = state.cbsFields
      state.cbsFields.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newCbsFieldsState[index] = { ...action.data }
        }
      })
      return {
        ...state,
        cbsFields: newCbsFieldsState,
      }
    case configConstants.CONFIG_CBS_FIELD_DELETE_SUCCESS:
      return {
        ...state,
        cbsFields: [...state.cbsFields.filter(({ id }) => id !== action.id)],
      }

    case configConstants.CONFIG_PROFIE_FIELD_GET_SUCCESS:
      return {
        ...state,
        profileFields: action.data,
      }
    case configConstants.CONFIG_PROFILE_FIELD_SAVE_SUCCESS:
      return {
        ...state,
        profileFields: [
          ...state.profileFields,
          ...uniqueBy([...[action.data]], "id"),
        ],
      }
    case configConstants.CONFIG_PROFILE_FIELD_EDIT_SUCCESS:
      let newProfileFieldsState = state.profileFields
      state.profileFields.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newProfileFieldsState[index] = { ...action.data }
        }
      })
      return {
        ...state,
        profileFields: newProfileFieldsState,
      }
    case configConstants.CONFIG_PROFILE_FIELD_DELETE_SUCCESS:
      return {
        ...state,
        profileFields: [
          ...state.profileFields.filter(({ id }) => id !== action.id),
        ],
      }
    case configConstants.CONFIG_APPLICATION_COLUMN_EDIT_SUCCESS:
      let newApplicationColumnsState = state.applicationColumns
      state.applicationColumns.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newApplicationColumnsState[index] = { ...action.data }
        }
      })
      return {
        ...state,
        applicationColumns: newApplicationColumnsState,
      }
    case configConstants.CONFIG_APPLICATION_COLUMN_DELETE_SUCCESS:
      return {
        ...state,
        applicationColumns: [
          ...state.applicationColumns.filter(({ id }) => id !== action.id),
        ],
      }
    case configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_GET_SUCCESS:
      return {
        ...state,
        creditScoreReportSetup: action.data,
      }
    case configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_SAVE_SUCCESS:
      return {
        ...state,
        creditScoreReportSetup: [
          ...state.creditScoreReportSetup,
          ...uniqueBy([...[action.data]], "id"),
        ],
      }
    case configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_EDIT_SUCCESS:
      let newCreditScoreReportSetupState = state.creditScoreReportSetup
      state.creditScoreReportSetup.map((item, index) => {
        if (String(item.id) === String(action.data.id)) {
          newCreditScoreReportSetupState[index] = { ...action.data }
        }
      })
      return {
        ...state,
        creditScoreReportSetup: newCreditScoreReportSetupState,
      }
    case configConstants.CONFIG_CREDIT_SCORE_REPORT_SETUP_DELETE_SUCCESS:
      return {
        ...state,
        creditScoreReportSetup: [
          ...state.creditScoreReportSetup.filter(({ id }) => id !== action.id),
        ],
      }
    case configConstants.CONFIG_ADD_FIRST_COMMENT_SUCCESS:
      return {
        ...state,
        firstComments: action.data,
      }
    case configConstants.CONFIG_GET_FIRST_COMMENTS_SUCCESS:
      return {
        ...state,
        firstComments: action.data,
      }
    case configConstants.CONFIG_EDIT_FIRST_COMMENT_SUCCESS:
      return {
        ...state,
        firstComments: action.data,
      }
    case configConstants.CONFIG_DELETE_FIRST_COMMENT_SUCCESS:
      return {
        ...state,
        firstComments: [],
      }

    case configConstants.GET_REPORTS_QUESTION_SUCCESS:
      const updatedApplicantGenderGlobalState = action.data.filter((item) => {
        if (item.type === "APPLICANT_GENDER") {
          return item
        }
      })

      const updatedApplicantAgeGlobalState = action.data.filter((item) => {
        if (item.type === "APPLICANT_AGE") {
          return item
        }
      })

      const updatedApplicantMaritalStatusGlobalState = action.data.filter(
        (item) => {
          if (item.type === "APPLICANT_STATUS") {
            return item
          }
        }
      )

      const updatedApplicantLoanGlobalState = action.data.filter((item) => {
        if (item.type === "APPLICANT_LOAN") {
          return item
        }
      })

      const updatedNewApplicantGlobalState = action.data.filter((item) => {
        if (item.type === "NEW_APPLICANT") {
          return item
        }
      })

      const updatedNewLandSizeGlobalState = action.data.filter((item) => {
        if (item.type === "LAND") {
          return item
        }
      })

      return {
        ...state,
        applicantGenderGlobalState:
          updatedApplicantGenderGlobalState[0] || null,
        applicantAgeGlobalState: updatedApplicantAgeGlobalState[0] || null,
        applicantMaritalStatusGlobalState:
          updatedApplicantMaritalStatusGlobalState[0] || null,
        applicantLoanGlobalState: updatedApplicantLoanGlobalState[0] || null,
        newApplicantGlobalState: updatedNewApplicantGlobalState[0] || null,
        landSizeGlobalState: updatedNewLandSizeGlobalState[0] || null,
      }
    case configConstants.UPDATE_APPLICATION_GENDER_STATE:
      return {
        ...state,
        applicantGenderGlobalState: action.data,
      }
    case configConstants.UPDATE_APPLICATION_AGE_STATE:
      return {
        ...state,
        applicantAgeGlobalState: action.data,
      }

    case configConstants.UPDATE_APPLICATION_MARITAL_STATUS_STATE:
      return {
        ...state,
        applicantMaritalStatusGlobalState: action.data,
      }

    case configConstants.UPDATE_APPLICATION_LOAN_STATE:
      return {
        ...state,
        applicantLoanGlobalState: action.data,
      }

    case configConstants.UPDATE_NEW_APPLICATION_STATE:
      return {
        ...state,
        newApplicantGlobalState: action.data,
      }
    case configConstants.UPDATE_NEW_LAND_SIZE_STATE:
      return {
        ...state,
        landSizeGlobalState: action.data,
      }
    case configConstants.UPDATE_APPLICATION_CUSTOMER_PROFILE_STATE:
      return {
        ...state,
        applicantProfileGlobalState: action.data,
      }
    case configConstants.GET_CUSTOMER_PROFILE_QUESTION_REQUEST:
      return {
        ...state,
      }
    case configConstants.GET_CUSTOMER_PROFILE_QUESTION_SUCCESS:
      return {
        ...state,
        applicantProfileGlobalState: action.data,
      }
    case configConstants.GET_CUSTOMER_PROFILE_QUESTIONN_FAILURE:
      return {
        ...state,
      }
    default: {
      return state
    }
  }
}
