import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "shards-react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Wizard, Steps, Step } from "react-albus"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Preloader, Placeholder } from "react-preloading-screen"
import { isEmpty } from "lodash"

import { withTranslation } from "react-i18next"
import { applicationActions } from "../../applicationManager"
import { SetQuestionnaire } from "../../scoreLogic/views/SetQuestionnaire"
import { ApplicationColumns } from "./ApplicationColumns"
import { CreditScoreReport } from "./CreditScoreReport"
import { LoanDetails } from "./LoanDetails"
import { FirstComment } from "./FirstComment"
import { ReportDetails } from "./ReportDetails"
import { Navigation } from "../component/Navigation"
import { Heading } from "../component/Heading"
import { WizardActions } from "../component/WizardActions"
import { configActions } from ".."
import { cslActions } from "../../scoreLogic"
import PageTitle from "../../../components/common/PageTitle"

import "./Config.scss"
import "./Animations.scss"
import "./Navigation.scss"
import { CustomerProfile } from "./customerProfile"

const Config = ({ surveys, config, scoreLogic, dispatch, t }) => {
  const { uid, computationCodes } = scoreLogic
  // const { surveyDeployment } = applications;
  const { assets } = surveys
  const [questionnaireName, setQuestionnaireName] = useState("")
  const [point, setPoint] = useState(1)

  const noComputationCodes = isEmpty(computationCodes)
  useEffect(() => {
    if (!uid) {
      return
    }

    dispatch(cslActions.getFormulas(uid))
    dispatch(cslActions.getScoreCardProfiles(uid))
    dispatch(cslActions.getAssumptions(uid))
    dispatch(cslActions.getSurveyCashflowStatements(uid))
    dispatch(cslActions.getScoreCardCategories(uid))
    dispatch(cslActions.getScoreCardGrade(uid))
    dispatch(cslActions.getDocumentChecklist(uid))

    dispatch(configActions.getApplicationColumns(uid))
    dispatch(configActions.getProfileFields(uid))
    dispatch(configActions.getCbsFields(uid))
    dispatch(configActions.getCreditScoreReportSetup(uid))
    dispatch(configActions.getFirstComments(uid))
    dispatch(configActions.getReportQuestion(uid))
    dispatch(configActions.getProfileQuestion(uid))
    dispatch(cslActions.getSurveyWorkflowGroups(uid))

    const [xformId] = uid.split(":")
    dispatch(applicationActions.getFormFields(xformId))
  }, [uid, dispatch])

  const getQuestionnaire = useCallback(() => {
    if (!uid) {
      return
    }
    const [xformId] = uid.split(":")

    if (assets) {
      assets.map(
        (asset, idx) =>
          asset.uid === xformId && setQuestionnaireName(asset.name)
      )
    }
  }, [uid, assets])

  useEffect(() => {
    getQuestionnaire()
  }, [getQuestionnaire])

  useEffect(() => {
    uid && dispatch(cslActions.getComputationCodes(uid))
  }, [uid, noComputationCodes, dispatch])

  // useEffect(() => {
  //     uid && noComputationCodes && dispatch(cslActions.generateComputationCodes(uid));
  // }, [uid, surveyDeployment, noComputationCodes, dispatch]);

  const titles = {
    setQuestionaire: t(
      "module.scoreLogic.view.configuration.titles.setQuestionnaire"
    ),
    applicationColumns: t(
      "module.scoreLogic.view.configuration.titles.applicationColumns"
    ),
    profileFields: t(
      "module.scoreLogic.view.configuration.titles.profileFields"
    ),
    cbsFields: t("module.scoreLogic.view.configuration.titles.cbsFields"),
    creditScoreReport: t(
      "module.scoreLogic.view.configuration.titles.creditScoreReport"
    ),
    loanDetails: t("module.scoreLogic.view.configuration.titles.loanDetails"),
    firstComment: t("module.scoreLogic.view.configuration.titles.firstComment"),
    reportDetails: t(
      "module.scoreLogic.view.configuration.titles.reportDetails"
    ),
    customerProfile: t(
      "module.scoreLogic.view.configuration.titles.customerProfile"
    ),
  }

  const classes = uid
    ? "setup-questionnaire-complete"
    : "setup-questionnaire-incomplete"

  return (
    <Preloader>
      <Container fluid className="main-content-container px-4">
        <CssBaseline />
        <Row noGutters className="page-header pt-4 pb-1">
          <PageTitle
            sm="9"
            title={questionnaireName}
            className="text-sm-left"
          />
        </Row>
        <Row noGutters className="page-header py-1">
          <Col
            md="12"
            lg="10"
            className="mx-auto mt-4 score-logic relative h-full"
          >
            <Wizard
              render={({ step }) => (
                <>
                  <Heading titles={titles} point={point} />
                  <div className={`flex bg-white p-2 ${classes}`}>
                    {uid && <Navigation titles={titles} step={step} />}
                    <TransitionGroup className="score-logic-setup-wrapper h-full">
                      <CSSTransition
                        key={step.id}
                        className="score-logic-setup"
                        timeout={{ enter: 500, exit: 0 }}
                      >
                        <div className="score-logic-setup-steps fluid">
                          <Steps step={step}>
                            <Step
                              id="setQuestionaire"
                              render={() => (
                                <div className="content">
                                  <SetQuestionnaire
                                    desc={t(
                                      "module.scoreLogic.view.configuration.titles.questionnaireDescription"
                                    )}
                                  />
                                </div>
                              )}
                            />
                            <Step
                              id="applicationColumns"
                              render={() => (
                                <div className="content">
                                  <ApplicationColumns />
                                </div>
                              )}
                            />
                            <Step
                              id="profileFields"
                              render={() => (
                                <div className="content">
                                  <ApplicationColumns isProfileView />
                                </div>
                              )}
                            />
                            <Step
                              id="cbsFields"
                              render={() => (
                                <div className="content">
                                  <ApplicationColumns isCbsView />
                                </div>
                              )}
                            />
                            <Step
                              id="creditScoreReport"
                              render={() => (
                                <div className="content">
                                  <CreditScoreReport />
                                </div>
                              )}
                            />
                            <Step
                              id="loanDetails"
                              render={() => (
                                <div className="content">
                                  <LoanDetails />
                                </div>
                              )}
                            />
                            <Step
                              id="firstComment"
                              render={() => (
                                <div className="content">
                                  <FirstComment />
                                </div>
                              )}
                            />
                            <Step
                              id="reportDetails"
                              render={() => (
                                <div className="content">
                                  <ReportDetails />
                                </div>
                              )}
                            />
                            <Step
                              id="customerProfile"
                              render={() => (
                                <div className="content">
                                  <CustomerProfile />
                                </div>
                              )}
                            />
                          </Steps>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                  <WizardActions
                    titles={titles}
                    step={step}
                    setPoint={setPoint}
                  />
                </>
              )}
            />
          </Col>
        </Row>
      </Container>
      <Placeholder>
        <span>{t("component.exceptions.loading.title")}...</span>
      </Placeholder>
    </Preloader>
  )
}

function mapStateToProps(state) {
  const { config, surveys, applications, scoreLogic } = state
  return {
    config,
    surveys,
    applications,
    scoreLogic,
  }
}

const connectedConfig = withTranslation()(connect(mapStateToProps)(Config))
export { connectedConfig as Config }
