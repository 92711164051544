import React from "react"
import { Redirect } from "react-router-dom"

// Layout Types
import { DefaultLayout, IconSidebar } from "./layouts"

// Route Views
import { Overview, SummaryOverview } from "./modules/reports"
import {
  Applications,
  EditApplication,
  ViewApplication,
  ViewApplicationProfile,
  AddApplication,
  PaymentApplication,
  GroupsApplication,
  MembersApplication,
  VillageBanksApplication,
  Collection,
} from "./modules/applicationManager"
import { CreditReport } from "./modules/creditScore"
import { ScoreLogic } from "./modules/scoreLogic"
import { DocumentChecklist } from "./modules/documentChecklist"
import {
  RegisterView,
  UserProfileView,
  UserProfileLiteView,
  EditUserProfileView,
  LoginView,
  LogoutView,
  ForgotPasswordView,
  ForgotUsernameView,
  ResetPasswordView,
} from "./modules/authentication"
import {
  UsersListView,
  UsersAddView,
  UsersEditView,
} from "./modules/userManager"
import {
  Export,
  Import,
  BulkInit,
  SyncExternalIntegration,
  Audittrail,
  UploadRasterFile,
} from "./modules/tools"
import { MFIListView, MFIAddView, MFIEditView } from "./modules/mfiManager"
import { Config } from "./modules/config"
import { ErrorsView, NotFoundView } from "./views"
import { ClientOverview } from "./modules/client/views/overview"
import { Clients } from "./modules/client/views/arrears"
import { RepaymentsReports } from "./modules/reports/views/repayments"
import MyPendingTasks from "./modules/client/views/myPendingTasks"
import { PendingTasks } from "./modules/client/views/pendingTasks"
import { ImpactReport } from "./modules/reports/views/impact"
import { PaymentsReport } from "./modules/reports/views/paymentsReport"

const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar className="icon-sidebar-layout" noNavbar>
    {children}
  </IconSidebar>
)

export const publicRoutes = [
  {
    path: "/404",
    layout: BlankIconSidebarLayout,
    component: NotFoundView,
    roles: ["PUBLIC"],
  },
  {
    path: "/login",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: LoginView,
    roles: ["PUBLIC"],
  },
  {
    path: "/logout",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: LogoutView,
    roles: ["PUBLIC"],
  },
  {
    path: "/register",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: RegisterView,
    roles: ["PUBLIC"],
  },
  {
    path: "/reset-password/:resetPasswordToken",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: ResetPasswordView,
    roles: ["PUBLIC"],
  },
  {
    path: "/forgot-password",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: ForgotPasswordView,
    roles: ["PUBLIC"],
  },

  {
    path: "/forgot-username",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: ForgotUsernameView,
    roles: ["PUBLIC"],
  },
  {
    path: "/error",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: ErrorsView,
    roles: ["PUBLIC"],
  },
]

export const privateRoutes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/credit-score" />,
    roles: ["MFI_SUPERVISOR", "MFI_ADMIN"],
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/applications" />,
    roles: ["MFI_CREDIT_ANALYST"],
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/clients" />,
    roles: ["FA_ANALYST", "MFI_SUPERVISOR", "FA_ADMIN", "MFI_LOAN_OFFICER"],
  },
  {
    path: "/users",
    exact: true,
    layout: DefaultLayout,
    component: UsersListView,
    roles: ["MFI_ADMIN", "FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/users/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: UsersEditView,
    roles: ["MFI_ADMIN", "FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/users/add",
    exact: true,
    layout: DefaultLayout,
    component: UsersAddView,
    roles: ["MFI_ADMIN", "FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/user/profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileView,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_ADMIN",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/user/profile-lite",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLiteView,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_ADMIN",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/user/edit-profile",
    exact: true,
    layout: DefaultLayout,
    component: EditUserProfileView,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_ADMIN",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/applications",
    exact: true,
    layout: DefaultLayout,
    component: Applications,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "FA_ADMIN",
    ],
  },
  {
    path: "/applications/overview",
    exact: true,
    layout: DefaultLayout,
    component: Overview,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "FA_ADMIN",
    ],
  },
  {
    path: "/clients",
    exact: true,
    layout: DefaultLayout,
    component: ClientOverview,
    roles: ["MFI_LOAN_OFFICER", "FA_ANALYST", "MFI_SUPERVISOR", "FA_ADMIN"],
  },
  {
    path: "/clients/arrears",
    exact: true,
    layout: DefaultLayout,
    component: Clients,
    roles: ["MFI_LOAN_OFFICER", "FA_ANALYST", "MFI_SUPERVISOR", "FA_ADMIN"],
  },
  {
    path: "/clients/my-pending-tasks",
    exact: true,
    layout: DefaultLayout,
    component: MyPendingTasks,
    roles: ["MFI_LOAN_OFFICER", "FA_ANALYST", "MFI_SUPERVISOR", "FA_ADMIN"],
  },
  {
    path: "/clients/pending-tasks",
    exact: true,
    layout: DefaultLayout,
    component: PendingTasks,
    roles: ["MFI_SUPERVISOR", "FA_ADMIN"],
  },
  {
    path: "/applications/view/:id/:xformId/:xformversion",
    exact: true,
    layout: DefaultLayout,
    component: ViewApplication,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "FA_ADMIN",
    ],
  },
  {
    path: "/applications/profile/:id/:xformId/:xformversion",
    exact: true,
    layout: DefaultLayout,
    component: ViewApplicationProfile,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "FA_ADMIN",
    ],
  },
  {
    path: "/applications/edit/:id/:xformId/:xformversion",
    exact: true,
    layout: DefaultLayout,
    component: EditApplication,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_SUPERVISOR",
      "MFI_CREDIT_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/applications/add",
    exact: true,
    layout: DefaultLayout,
    component: AddApplication,
    roles: ["MFI_LOAN_OFFICER", "FA_ADMIN"],
  },
  {
    path: "/payments",
    exact: true,
    layout: DefaultLayout,
    component: PaymentApplication,
    roles: ["MFI_CREDIT_ANALYST", "MFI_SUPERVISOR", "FA_ADMIN"],
  },
  {
    path: "/groups",
    exact: true,
    layout: DefaultLayout,
    component: GroupsApplication,
    roles: ["MFI_LOAN_OFFICER", "MFI_SUPERVISOR"],
  },
  {
    path: "/collections",
    exact: true,
    layout: DefaultLayout,
    component: Collection,
    roles: ["MFI_LOAN_OFFICER", "MFI_SUPERVISOR"],
  },
  {
    path: "/members",
    exact: true,
    layout: DefaultLayout,
    component: MembersApplication,
    roles: ["MFI_LOAN_OFFICER", "MFI_SUPERVISOR"],
  },
  {
    path: "/villageBanks",
    exact: true,
    layout: DefaultLayout,
    component: VillageBanksApplication,
    roles: ["MFI_SUPERVISOR"],
  },
  {
    path: "/applications/credit-score-report/:id/:xformId/:xformversion",
    exact: true,
    layout: DefaultLayout,
    component: CreditReport,
    roles: [
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_LOAN_OFFICER",
      "FA_ADMIN",
    ],
  },
  {
    path: "/credit-score",
    exact: true,
    layout: DefaultLayout,
    component: ScoreLogic,
    roles: ["FA_ANALYST", "MFI_ADMIN", "FA_ADMIN"],
  },
  {
    path: "/reports/overview",
    exact: true,
    layout: DefaultLayout,
    component: Overview,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_ADMIN",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/reports/summary",
    exact: true,
    layout: DefaultLayout,
    component: SummaryOverview,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_ADMIN",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/reports/impact",
    exact: true,
    layout: DefaultLayout,
    component: ImpactReport,
    roles: ["MFI_ADMIN", "FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/reports/paymentsReport",
    exact: true,
    layout: DefaultLayout,
    component: PaymentsReport,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_SUPERVISOR",
      "MFI_CREDIT_ANALYST",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/reports/repayments",
    exact: true,
    layout: DefaultLayout,
    component: RepaymentsReports,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_SUPERVISOR",
      "MFI_CREDIT_ANALYST",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/document-checklist",
    exact: true,
    layout: DefaultLayout,
    component: DocumentChecklist,
    roles: [
      "MFI_LOAN_OFFICER",
      "MFI_CREDIT_ANALYST",
      "MFI_SUPERVISOR",
      "MFI_ADMIN",
      "FA_ANALYST",
      "FA_ADMIN",
    ],
  },
  {
    path: "/config",
    exact: true,
    layout: DefaultLayout,
    component: Config,
    roles: ["FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/tools/export",
    exact: true,
    layout: DefaultLayout,
    component: Export,
    roles: ["MFI_SUPERVISOR", "FA_ANALYST", "FA_ADMIN"],
  },

  {
    path: "/tool/integration",
    exact: true,
    layout: DefaultLayout,
    component: SyncExternalIntegration,
    roles: ["MFI_ADMIN"],
  },
  {
    path: "/tools/import",
    exact: true,
    layout: DefaultLayout,
    component: Import,
    roles: ["MFI_SUPERVISOR", "FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/tools/bulkInit",
    exact: true,
    layout: DefaultLayout,
    component: BulkInit,
    roles: ["MFI_SUPERVISOR", "FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/tools/audit",
    exact: true,
    layout: DefaultLayout,
    component: Audittrail,
    roles: ["MFI_ADMIN"],
  },
  {
    path: "/tools/integration",
    exact: true,
    layout: DefaultLayout,
    component: SyncExternalIntegration,
    roles: ["FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/tools/upload-raster-file",
    exact: true,
    layout: DefaultLayout,
    component: UploadRasterFile,
    roles: ["FA_ANALYST"],
  },
  {
    path: "/mfis",
    exact: true,
    layout: DefaultLayout,
    component: MFIListView,
    roles: ["FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/mfis/add",
    exact: true,
    layout: DefaultLayout,
    component: MFIAddView,
    roles: ["FA_ANALYST", "FA_ADMIN"],
  },
  {
    path: "/mfis/edit/:id/:mfiCode",
    exact: true,
    layout: DefaultLayout,
    component: MFIEditView,
    roles: ["FA_ANALYST", "FA_ADMIN"],
  },
]
